
import Vue from 'vue';
import Router from 'vue-router';
import LoginRouter from './login';
import HomeRouter from './home';

Vue.use(Router);

const routes = [
	{
        path: '',
        redirect: '/login'
    },
	...LoginRouter,
	...HomeRouter
]

const router = new Router({
	mode: "hash",
	routes,
});



export default router;