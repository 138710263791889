/**
 * id
 * title：菜单标题
 * display：是否显示（admin账号无限制）
 * path：跳转路径（需和router中的一致）
 * router：会组装返回到路由文件中
 */
export const MenuList = [
    {
        id: '1', title: '设备管理', display: true, children: [
            { id: '1 - 1', title: '设备信息', path: '/deviceInfo', name: 'deviceInfo', display: true, router: { path: '/deviceInfo', name: 'deviceInfo', component: () => import('./page/deviceManage/deviceInfo.vue') } },
        ]
    },
    {
        id: '2', title: '公共信息管理', display: false, children: [
            { id: '2 - 1', title: '设备公共信息', path: '/devicePublic', name: 'devicePublic', display: false, router: { path: '/devicePublic', name: 'devicePublic', component: () => import('./page/publicInfo/devicePublic.vue') } },
            { id: '2 - 2', title: '传感器公共信息', path: '/sensorPublic', name: 'sensorPublic', display: false, router: { path: '/sensorPublic', name: 'sensorPublic', component: () => import('./page/publicInfo/sensorPublic.vue') } },
        ]
    },
    {
        id: '3', title: '数据管理', display: true, children: [
            { id: '3 - 1', title: '设备数据', path: '/deviceData', name: 'deviceData', display: true, router: { path: '/deviceData', name: 'deviceData', component: () => import('./page/dataManage/deviceData.vue') } },
        ]
    },
    {
        id: '4', title: '系统管理', display: false, children: [
            { id: '4 - 1', title: '用户管理', path: '/userManage', name: 'userManage', display: false, router: { path: '/userManage', name: 'userManage', component: () => import('./page/sysManage/userManage.vue') } },
            { id: '4 - 2', title: '字典管理', path: '/dictManage', name: 'dictManage', display: false, router: { path: '/dictManage', name: 'dictManage', component: () => import('./page/sysManage/dictManage.vue') } },
            { id: '4 - 3', title: '番茄潜叶蛾小程序用户管理', path: '/wxUserManage', name: 'wxUserManage', display: false, router: { path: '/wxUserManage', name: 'wxUserManage', component: () => import('./page/sysManage/wxUserManage.vue') } },
        ]
    },
]

/** 不显示在菜单中的路由 */
export const elseRouter = [
    { path: '/abutmentDevice', name: 'abutmentDevice', component: () => import('./page/deviceManage/abutmentDeviceInfo.vue') },
    { path: '/sensorInfo', name: 'sensorInfo', component: () => import('./page/deviceManage/sensorInfo.vue') },
    { path: '/sensorCommand', name: 'sensorCommand', component: () => import('./page/deviceManage/sensorCommand.vue') },
    { path: '/dataShow', name: 'dataShow', component: () => import('./page/dataManage/dataShow/dataShow.vue') },

    // { path: '/sensorData', name: 'sensorData', component: () => import('./page/dataManage/sensorData.vue') },
    // { path: '/dataInfo', name: 'dataInfo', component: () => import('./page/dataManage/dataInfo.vue') }
]
