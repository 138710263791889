import { MenuList, elseRouter } from '@/menu.js';
import { CONSTANT } from '@/util/publicConstant'
import Cookies from 'js-cookie'

/** 处理添加路由 */
export const handleMenuRouter = () => {
    let tem = [];
    tem = MenuList.map((item) => {
        if (item.children && item.children.length > 0) {
            return item.children.map((child) => {
                return child.router;
            });
        }
    });
    const result = [];
    tem.forEach((item) => {
        result.push(...item);
    });
    elseRouter.forEach((item) => {
        result.push(item)
    })
    return result;
}
/** 处理菜单 */
export const handleMenuList = () => {
    const control = Cookies.get('account') === CONSTANT.account;
    let result = MenuList;
    if (!control) {
        result = MenuList.map((item) => {
            if (item && item.display && item.children && item.children.length > 0) {
                item.children = item.children.map((child) => {
                    if (child && child.display) {
                        return child;
                    }
                }).filter(Boolean);
                return item;
            }
        }).filter(Boolean);
    }
    return result;
}

/** 获取存在权限设置的路由 */
export const getDisableRouter = () => {
    const powerRouter = [];
    const allRouter = [];
    MenuList.forEach(item => {
        item.children.forEach(child => {
            allRouter.push(child.name);
            if (!child.display) {
                powerRouter.push(child.name)
            }
        })
    })
    elseRouter.forEach(item => {
        allRouter.push(item.name);
    })
    allRouter.push(...['home', 'login']);
    return { powerRouter, allRouter };
}