import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI, { Loading } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { CONSTANT } from './util/publicConstant.js'
import { getDisableRouter } from './util/handleRouter.js'
import Cookies from 'js-cookie'

require('./global/common.less');

Vue.use(ElementUI);

/** 常量赋值 */
Object.keys(CONSTANT).forEach((key) => {
	Vue.prototype['$' + key] = CONSTANT[key];
})

let loadingInstance = '';
/**
 * 显示全屏加载
 */
Vue.prototype.$showLoading = function () {
	if (loadingInstance) {
		loadingInstance.close();
	}
	loadingInstance = Loading.service({
		target: '#app',
		spinner: 'nz-icon-loading',
		background: 'rgba(255, 255, 255, 0)',
	});
};

/**
 * 关闭全屏loading
 */
Vue.prototype.$closeLoading = function () {
	loadingInstance.close();
};

// 全局路由拦截
router.beforeEach((to, from, next) => {
	const { powerRouter, allRouter } = getDisableRouter();
	const token = Cookies.get('token');
	const account = Cookies.get('account');
	if (token) {
		if(to.name === "home"){
			next();
		}else if ((account !== CONSTANT.account && powerRouter.includes(to.name)) || !allRouter.includes(to.name)) {
			next({
				path: '/home',
				replace: true,
			})
		} else {
			next();
		}
	} else {
		if (to.name !== "login") {
			next({
				path: '/login',
				replace: true,
			})
		} else {
			next();
		}
	}
})


new Vue({
	router,
	render: h => h(App),
}).$mount("#app");