<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less" >
.app {
  position: absolute;
  height: 100%;
  width: 100%;
}
html, body {
    width: 100%;
    height: 100%;
    margin: 0px;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  width: 10px;
  background: #C1C1C1;
}
</style>